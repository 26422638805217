import { Territoire } from "@/core/models/enum/enumeration";
import { v4 as uuidv4 } from 'uuid';

export const buildDataToSend =(data) => {
      
    return {
       id:data.id,
       bandeAutreLibelle:data.bandeAutreLibelle,
       bandeAutreFin:data.bandeAutreFin,
       bandeAutreDebut:data.bandeAutreDebut,
       bande:data.bande,
       stationA:{
           code : "A",
           nom: data.stationANom,
          //  debit : data.stationADebit,
           debitRx : data.stationADebitRx,
           debitTx : data.stationADebitTx,
           puissanceEmise:data.stationAPuissanceEmise,
           frequenceRx : data.stationAFrequenceRx,
           frequenceTx : data.stationAFrequenceTx,

           nomClient : data.stationANomClient,
           site : {
               entGeo: {  id: data.stationASite.entGeo.id},
               latitude: data.stationASite.latitude,
               longitude: data.stationASite.longitude,
               nomVilleInternationale:data.stationASite.nomVilleInternationale
           }
       },
       stationB:{
        code : "B",
        nom: data.stationBNom,
        // debit : data.stationBDebit,
        debitRx : data.stationBDebitRx,
        debitTx : data.stationBDebitTx,
        puissanceEmise:data.stationBPuissanceEmise,
        frequenceRx : data.stationBFrequenceRx,
        frequenceTx : data.stationBFrequenceTx,

        nomClient : data.stationBNomClient,
        site : {
            entGeo: {  id: data.stationBSite.entGeo.id},
            latitude: data.stationBSite.latitude,
            longitude: data.stationBSite.longitude,
            nomVilleInternationale:data.stationBSite.nomVilleInternationale
        }
    },
      }
   }

 export const setData =(data: any = undefined) => {
    return {
      id:data == undefined ? uuidv4() : data.id,
      bande: data == undefined ? {id: "",libelle: "",debut: undefined,fin: undefined} : data.bande ,
      bandeAutreLibelle: data == undefined ? "" : data.bandeAutreLibelle,
      bandeAutreDebut: data == undefined ? undefined : data.bandeAutreDebut,
      bandeAutreFin: data == undefined ? undefined : data.bandeAutreFin,
      stationACode : "A",
      stationANom:  data == undefined ? "" : data.stationA.nom ,
      // stationADebit : data == undefined ? undefined : data.stationA.debit,
      stationADebitRx : data == undefined ? undefined : data.stationA.debitRx,
      stationADebitTx : data == undefined ? undefined : data.stationA.debitTx,
      stationAFrequenceRx : data == undefined ? undefined : data.stationA.frequenceRx,
      stationAFrequenceTx : data == undefined ? undefined : data.stationA.frequenceTx,
      stationANomClient : data == undefined ? "" : data.stationA.nomClient,
      stationATerritoire : data == undefined ? Territoire.NATIONAL : Territoire.NATIONAL,
      stationASite : {
            entGeo : {id:  data == undefined ? "" : data.stationA.site.entGeo.id},
            latitude: data == undefined ? "" : data.stationA.site.latitude,
            longitude: data == undefined ? "" : data.stationA.site.longitude,
            nomVilleInternationale:data == undefined ? "" : data.stationA.site.nomVilleInternationale
          },
      stationBCode : "B",
      stationBNom:  data == undefined ? "" : data.stationB.nom ,
      // stationBDebit : data == undefined ? undefined : data.stationB.debit,
      stationBDebitRx : data == undefined ? undefined : data.stationB.debitRx,
      stationBDebitTx : data == undefined ? undefined : data.stationB.debitTx,
      stationBFrequenceRx : data == undefined ? undefined : data.stationB.frequenceRx,
      stationBFrequenceTx : data == undefined ? undefined : data.stationB.frequenceTx,
      stationBNomClient : data == undefined ? "" : data.stationB.nomClient,
      stationBTerritoire : data == undefined ? Territoire.NATIONAL : Territoire.NATIONAL,
      stationBSite : {
            entGeo : {id:  data == undefined ? "" : data.stationB.site.entGeo.id},
            latitude: data == undefined ? "" : data.stationB.site.latitude,
            longitude: data == undefined ? "" : data.stationB.site.longitude,
            nomVilleInternationale:data == undefined ? "" : data.stationB.site.nomVilleInternationale
          },
     }
  }