import { defineComponent, ref, computed, watch,onMounted } from "vue";
import { Territoire } from "@/core/models/enum/enumeration";
import _ from "lodash";
import Yup from "@/bundle/validations/YupExpended";
import { ErrorMessage, Field, useForm, useField} from "vee-validate";
import { useBande } from "@/composables/useBande";
import useEmitter from "@/composables/useEmitter";
import { useEntGeos } from "@/composables/useEntGeos";
import { setData,buildDataToSend } from "./dataFormatter";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

interface newStationDataBlock {
   id?:string;
   bande : Bande;
   bandeAutreLibelle: string|undefined;
   bandeAutreDebut: number|undefined;
   bandeAutreFin: number|undefined;
   stationACode: string;
   stationANom: string;
   stationASite : Site;
   stationATerritoire: Territoire,
  //  stationADebit: number|undefined;
   stationADebitRx: number|undefined;
   stationADebitTx: number|undefined;
   stationAFrequenceRx: number|undefined;
   stationAFrequenceTx: number|undefined;
   stationANomClient:string|undefined

   stationBCode: string;
   stationBNom: string;
   stationBSite : Site;
   stationBTerritoire: Territoire,
  //  stationBDebit: number|undefined;
   stationBDebitRx: number|undefined;
   stationBDebitTx: number|undefined;
   stationBFrequenceRx: number|undefined;
   stationBFrequenceTx: number|undefined;
   stationBNomClient:string|undefined
}

interface Bande {
  id: string| undefined;
  libelle: string;
  debut: number|undefined;
  fin: number|undefined;
}

interface Site {
  nomVilleInternationale:string|undefined,
  longitude: string;
  latitude: string;
  entGeo : EntGeo
}


interface EntGeo {
  id: string;

}

export default defineComponent({
  name: "new-vsat-modal",
  components: {ErrorMessage, Field},
  setup(props,context) {

    const keySelect = ref(0);
    const emitter = useEmitter();
    const showModal = ref(false);
    const edit = ref(false);
    const loading     = ref<boolean>(false);

    const {getBandeParReseau, getBandeDescription, bandeIsAutre,getDefaultBande}  = useBande("vsat");
    const {villesGabonaises, paysSansLeGabon} = useEntGeos();
    const pays = ref<any[]>([]);
    const store = useStore();

    
    const { resetField:resetFieldBande,meta : metaBande} = useField("bande")

    const stationData = ref<newStationDataBlock>({
      id:"",
      bande: {
        id: "",
        libelle: "",
        debut: undefined,
        fin: undefined
      },
      bandeAutreDebut:undefined,
      bandeAutreFin:undefined,
      bandeAutreLibelle:"",
      stationACode: "A",
      stationANom: "",
      stationASite: {
        longitude: "",
        latitude: "",
        nomVilleInternationale:"",
        entGeo : {
          id: ""
        }
      },
      stationATerritoire: Territoire.NATIONAL,
      // stationADebit: undefined,
      stationADebitRx: undefined,
      stationADebitTx: undefined,
      stationAFrequenceRx: undefined,
      stationAFrequenceTx: undefined,
      stationANomClient:"",

      stationBCode: "B",
      stationBNom: "",
      stationBSite: {
        longitude: "",
        latitude: "",
        nomVilleInternationale:"",
        entGeo : {
          id: ""
        }
      },
      stationBTerritoire: Territoire.NATIONAL,
      // stationBDebit: undefined,
      stationBDebitRx: undefined,
      stationBDebitTx: undefined,
      stationBFrequenceRx: undefined,
      stationBFrequenceTx: undefined,
      stationBNomClient:"",

    });

    const bandeKey = ref(0);
    const localiteAKey = ref(0);
    const localiteBKey = ref(0);
    const localiteB = ref(null);
    
    const copy = Object.assign({}, stationData.value)

    //******/ COMPUTED PROPERTIES DBT


    const bandes = computed(() => {
      return getBandeParReseau('vsat');
    });

    const title = computed(() => {
          return edit.value ? "Modification d'une station" : "Ajout d'une station";
    });

    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });

    const isNationalA = computed(() => {
      return  stationData.value.stationATerritoire == Territoire.NATIONAL
    });

    const isNationalB = computed(() => {
      return  stationData.value.stationBTerritoire == Territoire.NATIONAL
    });


    const validationSchema = 
      Yup.object().shape({
        id:Yup.string().notRequired().default(''),
        bande : Yup.object().shape({
          id: Yup.string().required().label("Le type de la bande"),
          debut: Yup.number().required().label("Le début de la bande fréquence"),
          libelle: Yup.string().default("").label("La BA"),
          fin: Yup.number().required().label("La fin de la bande  fréquence"),
        }),
        bandeAutreLibelle: Yup.string().notRequired().label("La BA"),
        bandeAutreDebut: Yup.number().when('bande.id', {
          is: (id) => {
            return bandeIsAutre(id);

          } ,
          then: (schema) => schema.required().positive().when('bandeAutreDebut',{
            is: (value) => value != undefined && value !="",
            then: (schema) => schema.lessThan(Yup.ref("bandeAutreFin"),"La fréq. de début de bande doit être inferieure à ${less} MHz")
          }),
          otherwise: (schema) => schema.nullable(true),
        }).label("La fréquence de début de bande"),
        bandeAutreFin: Yup.number().when('bande.id', {
          is: (id) => bandeIsAutre(id),
          then: (schema) => schema.required().positive().when('bandeAutreDebut',{
            is: (value) => value != undefined && value !="",
            then: (schema) => schema.moreThan(Yup.ref("bandeAutreDebut"),"La fréq. de fin de bande doit être superieure à ${more} MHz")
          }),
          otherwise: (schema) => schema.nullable(true),
        }).label("La fréquence de fin de bande"),
        stationACode: Yup.string().required().label("Le code").default('A'),
        stationANom: Yup.string().required().label("Le nom"),
        // stationADebit: Yup.number().positive().required().label("Le débit"),
        stationADebitRx: Yup.number().positive().required().label("Le débit RX"),
        stationADebitTx: Yup.number().positive().required().label("Le débit TX"),
        stationAFrequenceTx: Yup.number().positive().required()
        // .when('stationAFrequenceRx',{
        //   is: (value) => value != undefined && value !="",
        //   then: (schema) => schema.lessThan(Yup.ref("stationAFrequenceRx"),"La fréquence Tx doit être inferieure à ${less} MHz")
        // })
        .when('bande',{
          is: (value) => value != undefined && value !="" && value?.debut !=undefined && value?.debut !=undefined,
          then: (schema) => {
            return schema.inclusDansBandeFrequence(stationData.value.bande.debut,stationData.value.bande.fin)
          }
        }).label("La fréq. TX"),
        stationAFrequenceRx: Yup.number().positive().required()
        .when('bande',{
          is: (value) => value != undefined && value !="" && value?.debut !=undefined && value?.debut !=undefined,
          then: (schema) => {
            return schema.inclusDansBandeFrequence(stationData.value.bande.debut,stationData.value.bande.fin)
          }
        }).label("La fréq. RX"),
        stationANomClient: Yup.string().notRequired().label("Le nom du client"),
        stationATerritoire: Yup.mixed().required().oneOf([Territoire.NATIONAL,Territoire.INTERNATIONAL]).label('Le territoire'),
        stationASite: Yup.object().shape({
          latitude: Yup.string().latitude().required().label("La latitude"),
          longitude: Yup.string().longitude().required().label("La longitude"),
          nomVilleInternationale: Yup.string().notRequired().when('territoire',{
            is: value => value == Territoire.INTERNATIONAL,
            then: Yup.string().required(),
            }).label('La ville'),
          entGeo : Yup.object().shape({
            id:Yup.string().required().label(isNationalA.value ? "La ville" : "Le pays")
          }),
          }),
          stationBCode: Yup.string().required().label("Le code").default('A'),
          stationBNom: Yup.string().required().label("Le nom"),
          // stationBDebit: Yup.number().positive().required().label("Le débit"),
          stationBDebitRx: Yup.number().positive().required().label("Le débit RX"),
          stationBDebitTx: Yup.number().positive().required().label("Le débit TX"),
          stationBFrequenceTx: Yup.number().positive().required()
          // .when('stationAFrequenceRx',{
          //   is: (value) => value != undefined && value !="",
          //   then: (schema) => schema.positive().required().lessThan(Yup.ref("stationBFrequenceRx"),"La fréquence Tx doit être inferieure à ${less} MHz")
          // })
          .when('bande',{
            is: (value) => value != undefined && value !="" && value?.debut !=undefined && value?.debut !=undefined,
            then: (schema) => {
              return schema.positive().required().inclusDansBandeFrequence(stationData.value.bande.debut,stationData.value.bande.fin)
            }
          }).label("La fréq. TX"),
          stationBFrequenceRx: Yup.number().positive().required()
          .when('bande',{
            is: (value) => value != undefined && value !="" && value?.debut !=undefined && value?.debut !=undefined,
            then: (schema) => {
              return schema.positive().required().inclusDansBandeFrequence(stationData.value.bande.debut,stationData.value.bande.fin)
            }
          }).label("La fréq. RX"),
          stationBNomClient: Yup.string().notRequired().label("Le nom du client"),
          stationBTerritoire: Yup.mixed().required().oneOf([Territoire.NATIONAL,Territoire.INTERNATIONAL]).label('Le territoire'),
          stationBSite: Yup.object().shape({
            latitude: Yup.string().latitude().required().label("La latitude"),
            longitude: Yup.string().longitude().required().label("La longitude"),
            nomVilleInternationale: Yup.string().notRequired().when('territoire',{
              is: value => value == Territoire.INTERNATIONAL,
              then: Yup.string().required(),
              }).label('La ville'),
            entGeo : Yup.object().shape({
              id:Yup.string().required().label(isNationalB.value ? "La ville" : "Le pays")
            }),
            }),
          }) 

    //******/ COMPUTED PROPERTIES FIN

    const { resetForm, handleSubmit ,meta,values:formValues, errors, setErrors } = useForm<newStationDataBlock>({
      validationSchema: validationSchema,
    });

    const initModal = ( vsat : any  = undefined) => 
    {
      
      edit.value = vsat.value == undefined ? false : true
      edit.value ? setDataToForm(vsat.value) : setDataToForm(undefined);
      localiteB.value = vsat?.value?.stationB?.site?.entGeo?.id;
      
      keySelect.value++;
      bandeKey.value++;
      localiteAKey.value++;
      localiteBKey.value++;

      
    }

    onMounted(() => {
      resetFieldBande();      
      

      store.dispatch(Actions.GET_PAYS).then((data) => {
        pays.value = [...data]
      })
      
    })

    const reset = () => {
      // Réinitialisation des données
      stationData.value = {...stationData.value,...meta.value.initialValues}
      resetForm({values: meta.value.initialValues});
      edit.value = false;      
    };

    const onClose= () => {
      reset()
    };
    
    const submit = handleSubmit((values) => {
      stationData.value = {...stationData.value,...values};
      var dataToSend : any = buildDataToSend(stationData.value)
      emitter.emit(edit.value ? 'vsatEntryUpdated' : 'newVsatEntryAdded', dataToSend);
      showModal.value = false
    });


    const setDataToForm =(data: any = undefined) => {

      resetForm({values : setData(data)})
      setErrors({})
      if(edit.value)
      {
        var stationDataX : newStationDataBlock = {
          id: data.id,
          bande: data.bande,
          bandeAutreDebut: data.bandeAutreDebut,
          bandeAutreFin: data.bandeAutreFin,
          bandeAutreLibelle: "",
          stationACode: "A",
          stationANom: data.stationA.nom,
          stationASite: {
            longitude: data.stationA.site.longitude,
            latitude: data.stationA.site.latitude,
            nomVilleInternationale:"",
            entGeo : {
              id: data.stationA.site.entGeo.id
            }
          },
          stationATerritoire: Territoire.NATIONAL,
          // stationADebit: data.stationA.debit,
          stationADebitRx: data.stationA.debitRx,
          stationADebitTx: data.stationA.debitTx,
          stationAFrequenceRx: data.stationA.frequenceRx,
          stationAFrequenceTx: data.stationA.frequenceTx,
          stationANomClient: data.stationA.nomClient,
    
          stationBCode: "B",
          stationBNom: data.stationB.nom,
          stationBSite: {
            longitude: data.stationB.site.longitude,
            latitude: data.stationB.site.latitude,
            nomVilleInternationale: data.stationB.site.nomVilleInternationale ? data.stationB.site.nomVilleInternationale : "",
            entGeo : {
              id: data.stationB.site.entGeo.id
            }
          },
          stationBTerritoire: data.stationB.site.nomVilleInternationale ? Territoire.INTERNATIONAL : Territoire.NATIONAL,
          // stationBDebit: data.stationB.debit,
          stationBDebitRx: data.stationB.debitRx,
          stationBDebitTx: data.stationB.debitTx,
          stationBFrequenceRx: data.stationB.frequenceRx,
          stationBFrequenceTx: data.stationB.frequenceTx,
          stationBNomClient: data.stationB.nomClient,
    
        };

        stationData.value = {...stationDataX};
      }

    }

    // Watchers DBT 
    watch(() => _.cloneDeep(stationData.value.stationATerritoire), (currentValue, oldValue) => {
         // resetFieldStationAEntgeoId()
         // resetFieldStationAnomVilleInternationale()
      }
    );

    watch(() => _.cloneDeep(stationData.value.bandeAutreFin), (currentValue, oldValue) => {
      if(currentValue != undefined)
        stationData.value.bande.fin = Number(currentValue)
   }
   );

   watch(() => _.cloneDeep(stationData.value.bandeAutreDebut), (currentValue, oldValue) => {
    if(currentValue != undefined )
      stationData.value.bande.debut = Number(currentValue)
   }
    );
    watch(() => _.cloneDeep(stationData.value.stationBTerritoire), (currentValue, oldValue) => {
        
     // resetFieldStationBEntgeoId()
      //resetFieldStationBnomVilleInternationale();
      // localiteBKey.value++;     
      localiteBKey.value++;
      stationData.value.stationBSite.entGeo.id = "";
      
    });


    // Réinitialise les propriétés de la bande
    const onBandeClear = () => {
      stationData.value.bande ={
        id:"",
        debut:undefined,
        fin:undefined,
        libelle:""
      }
      resetAutreBande()
    }

    // En cas de changement de bande
    const onBandeChange = () => {
      //On réinitialise ces propriétés
      resetAutreBande()
    }

    const resetAutreBande = () => 
    {

      stationData.value.bandeAutreFin = undefined
      stationData.value.bandeAutreDebut = undefined
      var bandeRefAutre = getDefaultBande()
      bandeRefAutre.debut= undefined
      bandeRefAutre.fin = undefined
      
    }
    return {
      stationData,
      villesGabonaises,
      paysSansLeGabon,
      pays,
      showModal,
      initModal,
      title,
      submit,
      loading,
      onClose,
      errors,
      meta,
      actionButtonText,
      isNationalA,
      isNationalB,
      getBandeParReseau,
      bandes,
      getBandeDescription,
      bandeIsAutre,
      onBandeChange,
      onBandeClear,
      keySelect,
      bandeKey,
      localiteAKey,
      localiteBKey,
      localiteB
    };
  }
});
