import VsatListe from "./liste/VsatListe.vue"
import { defineComponent,ref,onMounted} from "vue";
import { Field, ErrorMessage,FieldArray,useFieldArray } from "vee-validate";

export default defineComponent({
  name: "vsat-form",
  props: {
     title : {type:String, required : true},
     subtitle : {type:String, required : true},
      icon : {type:String, required : true}
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray,
    VsatListe
  },
  setup(props,context) {

    const vsatListe = ref<InstanceType<typeof VsatListe>>();

     
    return {
      vsatListe,
    };
  }
});