import { defineComponent, computed,onMounted,ref} from "vue";
import type { ElTable } from 'element-plus'
import type { TableColumnCtx } from 'element-plus/es/components/table/src/table-column/defaults'
import NewVsatModal from "@/components/modals/forms/newVsatModal/NewVsatModal.vue"
import { Field, ErrorMessage,useFieldArray,useField } from "vee-validate";
import useEmitter from "@/composables/useEmitter";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import { AlertMessage } from '@/bundle/AlertMessage';
import _ from 'lodash';

interface Entry {
  id:string;
  code: string;
  nom: string;
  bande_id:string|undefined;
  site_longitude: string;
  site_latitude: string;
  site_entGeo_id : string;
  site_nomVilleInternationale:string|undefined,
  debit: number|undefined;
  debitRx: number|undefined;
  debitTx: number|undefined;
  frequenceRx: number|undefined;
  frequenceTx: number|undefined;
  nomClient:string|undefined
}


interface SpanMethodProps {
  row: Entry
  column: TableColumnCtx<Entry>
  rowIndex: number
  columnIndex: number
}

export interface Bande {
    id: string;
}

export interface BandeX {
    id: string;
    libelle: string;
    debut: number;
    fin: number;
  }

export default defineComponent({
  name: "vsat-liste",
  props: {
     bande : {type:Object,default : {} as BandeX},
     showAction : {type:Boolean, default : true},
  },
  components: {
    Field,
    ErrorMessage,
    NewVsatModal,
  },
  setup(props, context) {

    const emitter = useEmitter();
    const {getVilleDescription,getVilleInterationaleDescription,getEntgeoDescription} = useEntGeos();
    const vsatModal = ref<InstanceType<typeof NewVsatModal>>();
    var flatten = require('flat')
    const multipleTableRef = ref<InstanceType<typeof ElTable>>()
    const multipleSelection = ref<Entry[]>([])

    const { remove, push,update, fields} = useFieldArray('vsat.lignes');
    const { resetField:resetVsatLignes } = useField('vsat.lignes',value => !!value);

   


    onMounted(()=>{
        resetVsatLignes();
    })

    // on écoute l'évenement d'enregistrement de l'ajout d'une entrée FH
    //On ajoute la nouvelle entrée dans la liste
    emitter.on('newVsatEntryAdded', (entry) => { push(entry);})

    // on écoute l'évenement de la modification d'une entrée VSAT
    emitter.on('vsatEntryUpdated', (entry) => {
      
      var currentField = fields.value.find((item,index) => {
        //Si un objet à la même clé que notre entrée
        var it : any = item?.value;
        
        return it?.hasOwnProperty('id') && it?.id == entry?.id
      })

      if(currentField) update(currentField.key as number,entry)
        
    })

    const hasEntries = computed(() => { return fields.value.length > 0 })

    const data = computed(() => {
      let data :any = []
      fields.value.forEach(item => {
        data.push({...{},...item.value as any,...{key:Number(item.key)}})
      })
      return data
    })
    

    const openModal = (entry:Number|any = null) => {

          var currentField = fields.value.find(item => {
            return  entry !=null && item.key == entry/2
          })
          vsatModal.value!.$vfm.show('vsatModal',currentField?.value)
   }


    const hasItemSelected = computed(() => {
      return multipleSelection.value.length > 0;
    })

    const removeItem = (index) => {
      if(index%2 == 0)
      remove(index/2)
    
    }

    const indexePosition = (index) =>
    {
      var position : number ;
       if(index == 0) position =1
       else if(index == 1) position = 1
       else if(index == 2) position = 2
       else 
       {
        position = index % 2 == 0  ? (index/2)+1 : index-1
       }

       return position;
    }

    const stations = computed(() => {
      var data = [] as Entry[]

      if(hasEntries.value)
      {
        fields.value.forEach((item,index) => {
          
          var it : any = item?.value
          var station = Object.assign({id:"",stationA:station,stationB:station}, it)
          var stationA = {} as Entry
          stationA = {...stationA, ...station.stationA}
          stationA = flatten(stationA,{delimiter:"_"})
          stationA.id = it.id
          stationA.site_entGeo_id = getEntgeoDescription(stationA.site_entGeo_id,true)
          data.push(stationA)

          var stationB = {} as Entry
          stationB = {...stationB, ...station.stationB}
          stationB = flatten(stationB,{delimiter:"_"})
          stationB.id = it.id
          stationB.site_entGeo_id = (stationB.site_nomVilleInternationale !="" && stationB.site_nomVilleInternationale != undefined) 
                                    ? getVilleInterationaleDescription(stationB.site_entGeo_id, stationB.site_nomVilleInternationale) 
                                    : getEntgeoDescription(stationB.site_entGeo_id,true)
          data.push(stationB)
        })
      }
      return data
    })

    const beforeDeleteSelection = (rows?: Entry[]) => {
        var message = "Souhaitez vous supprimer les couples de stations de VSAT que vous avez introduits.<br/> <strong></strong>"
        message += "<strong>N.B :</strong> Les informations supprimées seront définitivement perdues."
        var title =  "Confirmation de suppréssion"
        var options = {showCancelButton:true}

        AlertMessage.onWarning(message,title,options).then((d) => 
        {
          if(d.isConfirmed)
          {
            deleteSelection()
          }
        })
    }

    const deleteSelection = () => {
        // on recupere tous les ids de la selection
        var indexes : Array<string> = _.map(multipleSelection.value, 'id');
        // on prend les ids uniques
        let uniqueIndexes : Array<string> = [];
          indexes.forEach((element) => {
            if (!uniqueIndexes.includes(element)) {
              uniqueIndexes.push(element);
            }
        });
        
        //On parcourt la liste des ids
        uniqueIndexes.forEach((id,index) => 
        {
          var index : number = 0
          // on parcourt la liste des lignes
          for (let v of fields.value) {
            var it : any = v?.value
            //Si les ids correspondent
            if(it.id == id)
            {
              //on retire la ligne concernée
              remove(index);
              // on brise cette boucle
              break;
            }
            // on incrémente l'index
            index++;
          }
        })

        //on actualise la selection
        // multipleSelection.value.filter(item => {
        //   return !uniqueIndexes.includes(item.id )
        // })
    }

    const handleSelectionChange = (val: Entry[]) => {
      multipleSelection.value = val
    }

    const clearSelection = (rows?: Entry[]) => {
      multipleTableRef.value!.clearSelection()
    }

    const objectSpanMethod = ({
      row,
      column,
      rowIndex,
      columnIndex,
    }: SpanMethodProps) => {
      if (columnIndex === 0) 
      {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
      else if (columnIndex === 1) 
      {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
      else if (columnIndex === 12) 
      {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    }

    return {
      hasEntries,
      data,
      stations,
      fields,
      multipleTableRef,
      objectSpanMethod,
      openModal,
      clearSelection,
      getVilleDescription,
      handleSelectionChange,
      beforeDeleteSelection,
      multipleSelection,
      hasItemSelected,
      update,
      vsatModal,
      indexePosition,
      removeItem,
    };
  }
})