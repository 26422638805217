

import { Territoire } from "../enum/enumeration";
import { Bande } from "@/core/models/Bande";
import { Satellite } from "@/core/models/Satellite";
import { Site } from "@/core/models/Site";
import * as Yup from "yup";
import { TypeDocument } from "../TypeDocument";
import {Station}  from "@/core/models/Station";

export interface Couple {
    id: string;
    stationA: Station;
    stationB: Station;
}

export class StepVsat { 
    //field 
    territoire: Territoire|undefined;
    lignes: Array<Couple>
    numeroAutorisation: string|undefined;
    observation: string|undefined;


    //constructor 
    constructor() { 
        
        
        this.territoire = Territoire.NATIONAL
        this.observation = ""
        this.numeroAutorisation = undefined
        this.lignes = []
        //    {
        //     id:"",
        //     stationA:{
        //         code : "A",
        //         nom: '',
        //         debit : undefined,
        //         rxFrequence : undefined,
        //         txFrequence : undefined,
        //         nomClient : "",
        //         site : {
        //             entGeo: {  id: "",nom:""},
        //             latitude: "",
        //             longitude: ""
                    
        //         }
        //     },
        //     stationB : {
        //         code : "B",
        //         nom: '',
        //         debit : undefined,
        //         rxFrequence : undefined,
        //         txFrequence : undefined,
        //         nomClient : "",
        //         site : {
        //             entGeo: {  id: "",nom:""},
        //             latitude: "",
        //             longitude: ""
                    
        //         }
        //     }
        //    }
        
    }  

    static validationSchema() {

        const onlyNationalMessage = "Un réseau VSAT national ne doit contenir que des stations situées sur le territoire national."
        const mixStationMessage = "Un réseau VSAT international doit contenir au moins un couple de stations dont la station B est internationale. "
        return       Yup.object({
            numeroLicence: Yup.string().label("Le n° de licence"),
            vsat: Yup.object().shape
            ({
                numeroAutorisation: Yup.string().required().label("Le n° d'autorisation"),
              observation: Yup.string().notRequired().label("L'observation'"),
              territoire : Yup.mixed().required().oneOf(["NATIONAL","INTERNATIONAL"]).label('Le territoire'),
              lignes :      Yup.array().of(Yup.object()).when(['territoire'],{
                is: (territoire) => {
                     return (territoire == Territoire.NATIONAL);
                 },
                then: Yup.array().min(1).required().test('national',onlyNationalMessage ,function(value) {
                    // your logic
                    let st = value?.filter(station => {return station.stationA.site.nomVilleInternationale !="" || station.stationB.site.nomVilleInternationale !=""})
                    return st?.length == 0
                }),
                otherwise: Yup.array().min(1).required().test('international',mixStationMessage,function(value) {
                    // your logic
                    let data : Array<any> = value == undefined ? [] : value
                    let internationales   = data.filter(station => { return station.stationA.site.nomVilleInternationale =="" && station.stationB.site.nomVilleInternationale !=""})
                    let nationales        = data.filter(station => { return station.stationA.site.nomVilleInternationale =="" && station.stationB.site.nomVilleInternationale ==""})
                    
                    
                     return internationales?.length >= 1 && nationales.length >= 0
                })
                }).min(1,'La liste des stations du réseau VSAT doivent avoir au moins 1 élèment' )
                  .label('La liste des stations du réseau VSAT'),
            })
      })
    }
 }